import React from "react";

function CPIC() {
  return (
    <>
      {" "}
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/CPIC/CPIC_1.jpeg" alt="" />
            <img src="static/content/CPIC/CPIC_2.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Celosia Plumosa Ice Cream</h1>
            <p>
              Celosia plumosa Ice Cream is a stunning series of celosia that
              produces large, densely packed flower heads in a wide range of
              colors, including bright pink, orange, yellow, and red. The
              flowers resemble scoops of ice cream, hence the name.
              <br />
              <br />
              These celosia are perfect for adding a touch of drama to any
              garden. They are also a popular choice for cut flowers. The
              flowers are long-lasting and can be enjoyed for many weeks.
              <br />
              <br />
              Celosia plumosa Ice Cream is a relatively easy plant to grow. It
              prefers full sun and well-drained soil. The plants are drought
              tolerant and can survive on very little water. Celosia plumosa Ice
              Cream can be propagated from seed or cuttings.
              <br />
              <br />
              If you are looking for a colorful and eye-catching addition to
              your garden, Celosia plumosa Ice Cream is the perfect choice.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default CPIC;
