import React from "react";

function GreenBamboo() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/bamboo/green_B/green_B_1.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Green Bamboo</h1>
            <p>
              Bambusa bambos, also known as green bamboo or desi bamboo, is a
              large, woody bamboo species native to tropical and subtropical
              Asia. It is one of the most common bamboo species in India, and is
              widely used for construction, furniture making, papermaking, and
              food.
              <br />
              <br />
              Bambusa bambos is a relatively easy-to-care-for plant. It prefers
              full sun or partial shade, and well-drained soil. It is drought
              tolerant, but can benefit from regular watering.
              <br />
              <br />
              Bambusa bambos is a valuable and versatile plant. It is one of the
              most important bamboo species in India, and is used for a wide
              variety of purposes. It is also a beautiful and ornamental plant.
              <br />
              <br />
              Here are some of the benefits of Bambusa bambos:
              <br />
              1- Strong and durable
              <br />
              2- Easy to grow and care for
              <br />
              3- Drought tolerant.
              <br />
              4- Pest and disease resistant.
              <br />
              5- Versatile.
              <br />
              6- Beautiful and ornamental
              <br />
              <br />
              If you are looking for a strong, durable, and versatile bamboo
              species to add to your garden or home, Bambusa bambos is a great
              option.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default GreenBamboo;
