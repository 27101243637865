import React from "react";

function Vinca() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/vinca/Vinca_1_crop.jpg" alt="" />
            <img src="static/content/vinca/Vinca_2.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Vinca</h1>
            <p>
              The vinca flower, also known as periwinkle, is a beautiful and
              versatile flowering plant that is native to Madagascar, the Canary
              Islands, and the Azores. It is a member of the Apocynaceae family,
              which also includes other popular ornamental plants such as
              plumeria and oleander.
              <br />
              <br />
              Vinca flowers are typically five-petaled and come in a wide range
              of colors, including white, pink, red, purple, and yellow. They
              have a trumpet-shaped corolla and a central eye that is often a
              different color than the petals. Vinca flowers are typically 2-3
              cm (0.8-1.2 in) in diameter and are borne in clusters of 3-5.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default Vinca;
