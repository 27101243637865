import React from "react";

export default function Gazania() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/gazania/gazania_1.jpeg" alt="" />
            <img src="static/content/gazania/gazania_2.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Gazania Newday Mix</h1>
            <p>
              Gazania Newday Mix is a stunning blend of vibrant colors that will
              add a touch of drama to any garden. The large, daisy-like flowers
              are borne on sturdy stems above lush foliage, creating a truly
              eye-catching display. The Newday Mix includes a wide range of
              colors, including orange, yellow, red, pink, and white. The
              flowers can be solid colors, bi-colored, or even tri-colored. This
              mix is sure to add a touch of excitement to any garden.
              <br />
              <br />
              Gazania Newday Mix is a relatively easy plant to grow. It prefers
              full sun and well-drained soil. The plants are drought tolerant
              and can survive on very little water. Gazania Newday Mix can be
              propagated from seed or cuttings.
              <br />
              <br />
              The flowers of Gazania Newday Mix are long-lasting and can be
              enjoyed for many weeks. They are also a popular choice for cut
              flowers.
              <br />
              <br />
              If you are looking for a colorful and eye-catching addition to
              your garden, Gazania Newday Mix is the perfect choice.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}
