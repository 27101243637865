import React from "react";

export default function Calendula() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/calendula/calendula_1.jpeg" alt="" />
            <img src="static/content/calendula/calendula_2.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Calendula Bon Bon Mix</h1>
            <p>
              Calendula Bon Bon Mix is a delightful blend of calendula flowers
              in a range of vibrant colors, including yellow, orange, apricot,
              and cream. The flowers are typically 2-3 inches in diameter and
              have a daisy-like appearance. Calendula Bon Bon Mix flowers are
              long-lasting and can be enjoyed for many weeks.
              <br />
              <br />
              Calendulas are relatively easy to grow and can thrive in a variety
              of soil types. They prefer full sun but will also tolerate partial
              shade. Calendulas are drought tolerant and can survive on very
              little water. Calendula Bon Bon Mix can be propagated from seed or
              cuttings.
              <br />
              <br />
              The flowers of Calendula Bon Bon Mix are a popular choice for cut
              flowers. They are also a good source of nectar for bees and
              butterflies.
              <br />
              <br />
              In addition to their ornamental value, calendulas have a number of
              medicinal properties. The flowers are said to have
              anti-inflammatory, antiseptic, and digestive benefits. They are
              also used in traditional medicine to treat a variety of ailments,
              such as skin problems, wounds, and fevers.
              <br />
              <br />
              If you are looking for a colorful and versatile addition to your
              garden, Calendula Bon Bon Mix is the perfect choice.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}
