import React from "react";

export default function ToU() {
  return (
    <>
      <main>
        <div className="privacy_policy">
          <h3>AAA Nursery & Farms: Terms of Use</h3>
          <p className="last_updated"> Last Updated: October 27, 2023</p>
          <p>Welcome to AAA Nursery & Farms!</p>
          <p>
            By accessing and using our website, you agree to comply with and be
            bound by the following terms and conditions. If you do not agree to
            these terms, please do not use our website.
          </p>
          <p>
            <b>1. Acceptance of Terms</b>
          </p>
          <p>
            By using this website, you acknowledge that you have read,
            understood, and agree to these Terms of Use.
          </p>
          <p>
            <b>2. Use of Content</b>
          </p>
          <p>
            The content on this website is for informational purposes only. It
            provides information about the various flowers and plants available
            at AAA Nursery & Farms.
            <br />
            You may view, print, or download the content for personal,
            non-commercial use only.
            <br />
            You may not modify, reproduce, or distribute any content from this
            website without written permission from AAA Nursery & Farms.
          </p>

          <p>
            <b>3. User Conduct</b>
          </p>
          <p>
            You are responsible for any content you post or submit on our
            website, including comments, reviews, or other user-generated
            content. <br />
            You agree not to post any content that is offensive, illegal, or
            violates the rights of others. <br />
            We reserve the right to remove or edit user-generated content that
            violates these Terms of Use.
          </p>

          <p>
            <b>4. Privacy</b>
          </p>
          <p>
            Your use of this website is also governed by our Privacy Policy.
            Please review our Privacy Policy to understand how we collect, use,
            and protect your personal information.
          </p>

          <p>
            <b>5. Changes to Terms</b>
          </p>
          <p>
            We may update these Terms of Use from time to time. Any changes will
            be effective immediately upon posting. It is your responsibility to
            review these terms periodically for updates.
          </p>

          <p>
            <b>6. Limitation of Liability</b>
          </p>
          <p>
            We make every effort to provide accurate and up-to-date information
            on our website. However, we do not guarantee the accuracy,
            completeness, or reliability of the information.
            <br /> We are not responsible for any actions taken based on the
            information provided on our website.
            <br />
            We are not liable for any damages or losses resulting from your use
            of our website.
          </p>

          <p>
            <b>7. Governing Law</b>
          </p>
          <p>
            These Terms of Use are governed by and construed in accordance with
            the laws of India. Any disputes arising from these terms will be
            subject to the exclusive jurisdiction of the courts.
          </p>

          <p>
            <b>8. Contact Information</b>
          </p>
          <p>
            If you have any questions or concerns regarding these Terms of Use,
            please contact us.
          </p>

          <p>
            {" "}
            Thank you for visiting AAA Nursery & Farms and for respecting our
            Terms of Use. Enjoy exploring our collection of beautiful flowers
            and plants!
          </p>
        </div>
      </main>
    </>
  );
}
