import React from "react";

function Coleus() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/coleus/coleus_1.jpeg" alt="" />
            <img src="static/content/coleus/coleus_2.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Coleus Wizard</h1>
            <p>
              Coleus Wizard is a coleus variety with stunning foliage. It has
              large, serrated leaves with a unique pattern of bright red,
              orange, and yellow veins. The leaves are also edged in a deep
              green color.
              <br />
              <br />
              Coleus Wizard is a vigorous grower and can reach a height of 30-60
              cm (12-24 in). It prefers full sun to partial shade and
              well-drained soil. Coleus Wizard is a relatively low-maintenance
              plant and does not require a lot of care.
              <br />
              <br />
              Coleus Wizard is a popular choice for gardens, containers, and
              hanging baskets. It is also a popular cut flower. The leaves can
              be used to add a touch of color to salads, soups, and other
              dishes.
              <br />
              <br />
              Coleus Wizard is a beautiful and versatile plant that can be
              enjoyed in a variety of ways. It is a popular choice for adding a
              touch of color and drama to any garden.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default Coleus;
