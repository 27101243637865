import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Enquiry from "./pages/Enquiry";
import ToU from "./pages/ToU";
import PP from "./pages/PP";

// //////////////////////////////////////////////////////////////////
import Avenues from "./pages/Avenues";
import Bamboo from "./pages/Bamboo";
import Bonsai from "./pages/Bonsai";
import Creepers from "./pages/Creepers";
import Forestry from "./pages/Forestry";
import Fruits from "./pages/Fruits";
import GroundCover from "./pages/GroundCover";
import Ornamental from "./pages/Ornamental";
import Palms from "./pages/Palms";
import Shrubs from "./pages/Shrubs";
// ////////////////////////////////////////////////////////////////

import GoldenBamboo from "./pages/Bamboos/GoldenBamboo";
import BellyBamboo from "./pages/Bamboos/BellyBamboo";

// /////////////////////////////////////////////////////////////////
import BlogPalms from "./pages/Blogs/BlogPalms";
import BlogSeasonal from "./pages/Blogs/BlogSeasonal";
import BlogOrnamental from "./pages/Blogs/BlogOrnamental";
import SeasonalFlowers from "./pages/SeasonalFlowers";
import ContactUs from "./pages/ContactUs";
import NanaBamboo from "./pages/Bamboos/NanaBamboo";
import GreenBamboo from "./pages/Bamboos/GreenBamboo";
import GiantBamboo from "./pages/Bamboos/GiantBamboo";
import BlackBamboo from "./pages/Bamboos/BlackBamboo";

import Vinca from "./pages/Seasonals/Vinca";
import CCAM from "./pages/Seasonals/CCAM";
import CPIC from "./pages/Seasonals/CPIC";
import Coleus from "./pages/Seasonals/Coleus";
import Hyposystis from "./pages/Seasonals/Hyposystis";
import Torenia from "./pages/Seasonals/Torenia";
import Salvia from "./pages/Seasonals/Salvia";
import Dianthus from "./pages/Seasonals/Dianthus";
import Gazania from "./pages/Seasonals/Gazania";
import Aster from "./pages/Seasonals/Aster";
import Petunia from "./pages/Seasonals/Petunia";
import Calendula from "./pages/Seasonals/Calendula";
import Begonia from "./pages/Seasonals/Begonia";
import DianthusDiana from "./pages/Seasonals/DianthusDiana";
import Marigold from "./pages/Seasonals/Marigold";


function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/enquiry" element={<Enquiry />} />
        <Route path="/contactus" element={<ContactUs />} />

        <Route path="/Terms_of_use" element={<ToU />} />
        <Route path="/privacy_policy" element={<PP />} />
        {/* ----------------------------------------------------------------- */}
        <Route path="/avenues" element={<Avenues />} />
        <Route path="/bamboo" element={<Bamboo />} />
        <Route path="/bonsai" element={<Bonsai />} />
        <Route path="/creepers" element={<Creepers />} />
        <Route path="/forestry" element={<Forestry />} />
        <Route path="/fruits" element={<Fruits />} />
        <Route path="/ground_cover" element={<GroundCover />} />
        <Route path="/ornamental" element={<Ornamental />} />
        <Route path="/palms" element={<Palms />} />
        <Route path="/seasonal_flowers" element={<SeasonalFlowers />} />
        <Route path="/shrubs" element={<Shrubs />} />
        {/* ----------------------------------------------------------------------- */}
        <Route path="/golden_B" element={<GoldenBamboo />} />
        <Route path="/belly_B" element={<BellyBamboo />} />
        <Route path="/nana_B" element={<NanaBamboo />} />
        <Route path="/green_B" element={<GreenBamboo />} />
        <Route path="/black_B" element={<BlackBamboo />} />
        <Route path="/giant_B" element={<GiantBamboo />} />

        {/* ------------------------------------------------------------------------- */}

        <Route path="/vinca" element={<Vinca />} />
        <Route path="/CCAM" element={<CCAM />} />
        <Route path="/CPIC" element={<CPIC />} />
        <Route path="/coleus" element={<Coleus />} />
        <Route path="/hyposystis" element={<Hyposystis />} />
        <Route path="/torenia" element={<Torenia />} />
        <Route path="/dianthus" element={<Dianthus />} />
        <Route path="/salvia" element={<Salvia />} />
        <Route path="/gazania" element={<Gazania />} />
        <Route path="/aster" element={<Aster />} />
        <Route path="/petunia" element={<Petunia />} />
        <Route path="/begonia" element={<Begonia />} />
        <Route path="/calendula" element={<Calendula />} />
        <Route path="/dianthus_diana" element={<DianthusDiana />} />
        <Route path="/marigold" element={<Marigold />} />

        {/* ---------------------------------------------------------------------------- */}
        <Route path="/blog_palms" element={<BlogPalms />} />
        <Route path="/blog_seasonal" element={<BlogSeasonal />} />
        <Route path="/blog_ornamental" element={<BlogOrnamental />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
