import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const [navFixed, setNavFixed] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY >= 101) {
        setNavFixed(true);
      } else {
        setNavFixed(false);
      }
    });
  }, []);

  return (
    <>
      <header>
        <Link to="/">
          <div className="brand-group">
            <div className="brand">
              <img src="/static/favicon.png" alt="Brand" />
              <h1 style={{ marginRight: "50px" }}>AAA Nursery & Farms</h1>
            </div>

            <div className="brand-contacts">
              <img
                src="/static/phone.png"
                style={{ paddingTop: "14px" }}
                alt="phone"
              />
              <h5 style={{ paddingTop: "8px", color: "grey" }}>|</h5>
              <img
                src="/static/whatsapp.png"
                style={{ paddingTop: "14px" }}
                alt="whatsapp"
              />

              <p style={{ color: "black" }}>
                +919027845337 <br />
                +917505720630
              </p>

              <img
                src="/static/gmail.png"
                alt="mail"
                style={{ paddingTop: "8px" }}
              />
              <p style={{ color: "black", paddingTop: "8px" }}>
                aaanurseryandfarms@gmail.com
              </p>
            </div>
          </div>
        </Link>

        <nav
          className="navbar navbar-expand-lg"
          // style={{ backgroundColor: "goldenrod" }}
          style={
            navFixed
              ? {
                  position: "fixed",
                  top: "0",
                  width: "100%",
                  zIndex: "100",
                }
              : { position: "static" }
          }
        >
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              AAA Nursery & Farms
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/aboutus"
                  >
                    About Us
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Products
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/avenues">
                        Avenue Plants
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="/bamboo">
                        Bamboo
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/bonsai">
                        Bonsai Trees
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/creepers">
                        Creepers
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/forestry">
                        Forestry Plants
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="/fruits">
                        Fruit Plants
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/ground_cover">
                        Ground Covers
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="/ornamental">
                        Ornamental Plants
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/palms">
                        Palms
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/seasonal_flowers">
                        Seasonal Flowers{" "}
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="/shrubs">
                        Shrubs
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/enquiry"
                  >
                    Enquiry
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link active"
                    aria-current="page"
                    to="/contactus"
                  >
                    Contact Info{" "}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Navbar;
