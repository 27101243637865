import React from "react";

export default function Begonia() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/begonia/begonia_1.jpeg" alt="" />
            <img src="static/content/begonia/begonia_2_new.jpg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Begonia Cocktail Mix</h1>
            <p>
              Begonia Cocktail Mix is a blend of colorful and vibrant Begonia
              flowers. The mix includes a wide range of colors, such as red,
              orange, yellow, pink, and white. The flowers are typically 2-3
              inches in diameter and have a rose-like appearance. Begonia
              Cocktail Mix flowers are long-lasting and can be enjoyed for many
              weeks.
              <br />
              <br />
              Begonia Cocktail Mix is relatively easy to grow and can thrive in
              a variety of soil types. They prefer full sun to partial shade and
              well-drained soil. Begonia Cocktail Mix can be propagated from
              seed or cuttings.
              <br />
              <br />
              The flowers of Begonia Cocktail Mix are a popular choice for cut
              flowers. They are also a good source of nectar for bees and
              butterflies.
              <br />
              <br />
              If you are looking for a colorful and versatile addition to your
              garden, Begonia Cocktail Mix is the perfect choice.
              <br />
              <br />
            </p>
          </div>
        </div>
      </main>
    </>
  );
}
