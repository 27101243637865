import React from "react";

function Ground_cover() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/grass/grass_1.jpg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Ground Covers</h1>
            <p>
              Ground covers are low-growing plants that spread to form a dense
              mat, covering the ground. They can be used to suppress weeds,
              prevent erosion, and add visual interest to gardens. Ground covers
              come in a variety of types, including flowering plants, foliage
              plants, and succulents.
              <br />
              <br />
              When choosing ground covers, it is important to consider the
              following factors:
              <br />
              Sun exposure: Some ground covers prefer full sun, while others
              prefer shade.
              <br />
              Soil conditions: Some ground covers prefer well-drained soil,
              while others prefer moist soil.
              <br />
              Growth habit: Some ground covers are fast-growing, while others
              are slow-growing.
              <br />
              Maintenance requirements: Some ground covers require very little
              maintenance, while others require more regular pruning and care.
              <br />
              We can provide vast variety Ground covers at a very reasonable
              prices at your door step. Just contact us Or simply, drop an
              enquiry.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default Ground_cover;
