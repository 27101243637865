import React from "react";

export default function DianthusDiana() {
  return (
    <main>
      <div className="details_flower">
        <div className="details_flower_images">
          <img
            src="static/content/dianthus_diana/dianthus_diana_1.jpeg"
            alt=""
          />
          <img
            src="static/content/dianthus_diana/dianthus_diana_2.jpeg"
            alt=""
          />
        </div>
        <div className="details_flower_description">
          <h1>Dianthus Diana Mix</h1>
          <p>
            Dianthus Diana Mix is a perfect choice for adding a touch of color
            and beauty to any garden. It is a versatile plant that can be used
            in a variety of garden settings, such as borders, containers, and
            rock gardens. The flowers are long-lasting and can be enjoyed for
            many weeks, making them a valuable addition to any garden.
            <br />
            <br />
            In addition to their ornamental value, Dianthus Diana Mix flowers
            are also a good source of nectar for bees and butterflies. This
            makes them a beneficial addition to any garden, as they help to
            support pollinators.
            <br />
            <br />
            Dianthus Diana Mix is a relatively easy plant to grow and care for.
            They prefer full sun but will also tolerate partial shade. They are
            drought tolerant and can survive on very little water. This makes
            them a good choice for gardeners who live in areas with hot, dry
            summers.
            <br />
            <br />
            If you are looking for a colorful and versatile plant to add to your
            garden, Dianthus Diana Mix is a perfect choice.
          </p>
        </div>
      </div>
    </main>
  );
}
