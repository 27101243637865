import React from "react";

function BellyBamboo() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/bamboo/belly_B/belly_B_1.jpeg" alt="" />
            <img src="static/content/bamboo/belly_B/belly_B_2.jpeg" alt="" />
            <img src="static/content/bamboo/belly_B/belly_B_3.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Buddha Belly Bamboo</h1>
            <p>
              Buddha belly bamboo, also known as Bambusa ventricosa, is a
              stunning and unique species of bamboo native to Vietnam and
              southern China. It is known for its swollen internodes, which
              resemble the belly of a Buddha. Buddha belly bamboo is a popular
              ornamental plant, and is also used in bonsai.
              <br />
              <br />
              Buddha belly bamboo can grow up to 10 meters tall, but is
              typically grown in containers to keep its size in check. It
              prefers full sun to partial shade, and well-drained soil. Buddha
              belly bamboo is relatively drought tolerant, but can benefit from
              regular watering.
              <br />
              <br />
              To care for Buddha belly bamboo, water it regularly, especially
              during the hot summer months. Fertilize the plant once a month
              during the growing season with a balanced fertilizer. Buddha belly
              bamboo is relatively pest and disease resistant.
              <br />
              <br />
              Buddha belly bamboo is a beautiful and unique plant that can add a
              touch of elegance and sophistication to any garden or home.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default BellyBamboo;
