import React from "react";

function GoldenBamboo() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/bamboo/Golden_B/golden_b_1.jpeg" alt="" />
            <img src="static/content/bamboo/Golden_B/golden_b_2.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Golden Bamboo</h1>
            <p>
              Bambusa vulgaris, commonly known as bamboo, is a versatile and
              fascinating plant native to Southeast Asia. It has found
              widespread cultivation in tropical and subtropical regions across
              the globe due to its numerous qualities. This bamboo species is
              easily identifiable by its striking appearance, boasting bright
              yellow culms adorned with green stripes. What sets it apart is its
              remarkable growth potential, as it can reach towering heights of
              up to 30 meters and have a diameter of 10 centimeters.
              <br />
              <br />
              Beyond its aesthetic appeal, Bambusa vulgaris serves a multitude
              of practical purposes. It is a popular choice as an ornamental
              plant, enhancing the visual appeal of gardens and landscapes.
              <br />
              <br />
              Moreover, bamboo is highly regarded for its versatility, as it is
              used in various industries. Its robust nature makes it ideal for
              construction, contributing to buildings and structures in many
              regions. Additionally, bamboo is a valuable resource for furniture
              making, thanks to its durability and sustainability. Papermaking
              industries also benefit from its fibers, which are used in the
              production of paper products.
              <br />
              <br />
              While Bambusa vulgaris is a low-maintenance plant, it thrives in
              specific conditions. It prefers locations with full sun or partial
              shade and requires well-drained soil for optimal growth. This
              plant demonstrates an admirable level of resilience, as it can
              endure periods of drought. Nevertheless, it can benefit from
              periodic watering to promote healthier and more robust growth.
              Overall, Bambusa vulgaris stands as a testament to nature's
              ingenuity, offering beauty and utility in various aspects of human
              life and industry.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default GoldenBamboo;
