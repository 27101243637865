import { Link } from "react-router-dom";
import "./QuickAccess.css";
import React from "react";

export default function QuickAccess() {
  return (
    <>
      <main>
        <div className="circular-icons">
          <div className="icon-container">
            <Link to="/avenues">
              <img
                className="icon"
                src="static/content/avenues/avenues_2.jpeg"
                alt=""
              />
              <p>Avenues </p>
            </Link>
          </div>
          <div className="icon-container">
            <Link to="/bonsai">
              <img
                className="icon"
                src="static/content/bonsai/bonsai_1.jpeg"
                alt=""
              />
              <p>Bonsai </p>
            </Link>
          </div>
          <div className="icon-container">
            <Link to="/creepers">
              <img
                className="icon"
                src="static/content/creeper/creeper_1.jpg"
                alt="quickaccess"
              />
              <p>Creepers </p>
            </Link>
          </div>
          <div className="icon-container">
            <Link to="/seasonal_flowers">
              <img
                className="icon"
                src="static/content/salvia_vista/salvia_1.jpeg"
                alt="quickaccess"
              />
              <p>Seasonals</p>
            </Link>
          </div>
          <div className="icon-container">
            <Link to="/bamboo">
              <img
                className="icon"
                src="static/content/bamboo/Golden_B/golden_b_2.jpeg"
                alt="quickaccess"
              />
              <p>Bamboo</p>
            </Link>
          </div>
          <div className="icon-container">
            <Link to="/fruits">
              <img
                className="icon"
                src="static/content/fruits/fruits_3.jpeg"
                alt="quickaccess"
              />
              <p>Fruits</p>
            </Link>
          </div>
          <div className="icon-container">
            <Link to="/forestry">
              <img
                className="icon"
                src="static/content/forestry/forestry_1.jpeg"
                alt="quickaccess"
              />
              <p>Forestry</p>
            </Link>
          </div>
          <div className="icon-container">
            <Link to="/ground_cover">
              <img
                className="icon"
                src="static/content/grass/grass_1.jpg"
                alt="quickaccess"
              />
              <p>Covers</p>
            </Link>
          </div>
          <div className="icon-container">
            <Link to="/ornamental">
              <img
                className="icon"
                src="static/content/ornamental/ornamental_1.jpg"
                alt="quickaccess"
              />
              <p>Ornamentals</p>
            </Link>
          </div>
          <div className="icon-container">
            <Link to="/shrubs">
              <img
                className="icon"
                src="static/content/shrubs/shrubs_1.jpg"
                alt="quickaccess"
              />
              <p>Shrubs</p>
            </Link>
          </div>
          <div className="icon-container">
            <Link to="/palms">
              <img
                className="icon"
                src="static/content/palm/palm_1.jpeg"
                alt="quickaccess"
              />
              <p>Palms</p>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}
