import React from "react";

function Salvia() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/salvia_vista/salvia_1.jpeg" alt="" />
            <img src="static/content/salvia_vista/salvia_2.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Salvia Vista Red</h1>
            <p>
              Salvia Vista Red is a dwarf salvia cultivar that produces prolific
              displays of vibrant red flowers from late spring through early
              autumn. Its compact habit and extended bloom time make it a
              valuable addition to sunny borders, containers, and rock gardens.
              Requiring well-drained soil and regular irrigation, Salvia Vista
              Red thrives in full sun to partial shade. Its tolerance of heat
              and humidity makes it a suitable choice for gardens in warm
              climates.
              <br />
              <br />
              With its eye-catching blooms and manageable size, Salvia Vista Red
              is a versatile ornamental that can enhance a wide range of garden
              designs. Whether massed for impact or used as a colorful accent,
              this salvia is sure to add beauty and interest to any landscape.
            </p>
          </div>
        </div>
      </main>{" "}
    </>
  );
}

export default Salvia;
