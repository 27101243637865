import React from "react";

export default function Petunia() {
  return (
    <main>
      <div className="details_flower">
        <div className="details_flower_images">
          <img src="static/content/petunia/petunia_1.jpeg" alt="" />
          <img src="static/content/petunia/petunia_2.jpeg" alt="" />
        </div>
        <div className="details_flower_description">
          <h1>Petunia Picote Mix</h1>
          <p>
            Petunia Picote Mix is a blend of petunias with two colors on each
            flower, one in the center and another on the edge. The mix includes
            a wide range of colors, such as purple, pink, red, and white. The
            flowers are long-lasting and can be enjoyed for many weeks.
          </p>
        </div>
      </div>
    </main>
  );
}
