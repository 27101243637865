import React from "react";

function Bonsai() {
  return (
    <main>
      <div className="details_flower">
        <div className="details_flower_images">
          <img src="static/content/bonsai/bonsai_1.jpeg" alt="" />
          <img src="static/content/bonsai/bonsai_2.jpeg" alt="" />
          <img src="static/content/bonsai/bonsai_3.jpeg" alt="" />
        </div>
        <div className="details_flower_description">
          <h1>Bonsai Trees</h1>
          <p>
            Bonsai trees (literally "tray planting") are miniature trees grown
            in containers, developed from the traditional Chinese art form of
            penjing. The art of bonsai is to cultivate and train the tree to
            produce an aesthetically pleasing miniature replica of a mature tree
            in nature. Bonsai trees are often prized for their beauty and
            tranquility, and can be a valuable addition to any home or office.
            <br />
            <br />
            Here are some tips for caring for your bonsai tree:
            <br />
            Water your bonsai tree regularly, but be careful not to overwater
            it. The soil should be moist but not soggy.
            <br />
            Fertilize your bonsai tree during the growing season. Use a
            fertilizer that is specifically designed for bonsai trees.
            <br />
            Prune your bonsai tree regularly to maintain its shape and size.
            <br />
            Repot your bonsai tree every two to three years, or when the roots
            become pot-bound.
            <br />
            <br />
            We provide bonsai trees of peepel/Ficus trees, Banyan/bargad trees
            and many fruits trees. We can deliver vast varieties of bonsai trees
            at your door step at a very reasonable price. If you have any query
            please feel free to contact us.
          </p>
        </div>
      </div>
    </main>
  );
}

export default Bonsai;
