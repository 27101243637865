import React from "react";

export default function Aster() {
  return (
    <main>
      <div className="details_flower">
        <div className="details_flower_images">
          <img src="static/content/aster/aster_1.jpeg" alt="" />
          <img src="static/content/aster/aster_2.jpeg" alt="" />
        </div>
        <div className="details_flower_description">
          <h1>Aster Tall Mix</h1>
          <p>
            Aster Tall Mix is a captivating blend of vibrant aster flowers that
            will add a touch of elegance and sophistication to any garden. The
            mix includes a wide range of colors, such as white, pink, red,
            purple, and yellow. The flowers are typically 2-3 inches in diameter
            and have a daisy-like appearance. Aster Tall Mix flowers are
            long-lasting and can be enjoyed for many weeks.
            <br />
            <br />
            Asters are relatively easy to grow and can thrive in a variety of
            soil types. They prefer full sun but will also tolerate partial
            shade. Asters are drought tolerant and can survive on very little
            water. Aster Tall Mix can be propagated from seed or cuttings.
            <br />
            <br />
            The flowers of Aster Tall Mix are a popular choice for cut flowers.
            They are also a good source of nectar for bees and butterflies.
            <br />
            <br />
            If you are looking for a colorful and elegant addition to your
            garden, Aster Tall Mix is the perfect choice.
          </p>
        </div>
      </div>
    </main>
  );
}
