import React from "react";

function Ornamental() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/ornamental/ornamental_1.jpg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Ornamental Plants</h1>
            <p>
              Ornamental plants are grown for their beauty and aesthetic appeal.
              They can be used to add color, texture, and interest to gardens
              and landscapes. Ornamental plants can be grown indoors or
              outdoors, and they come in a wide variety of shapes, sizes, and
              colors.
              <br />
              <br />
              When choosing ornamental plants for your garden, it is important
              to consider the following factors:
              <br />
              Sun exposure: Some ornamental plants prefer full sun, while others
              prefer shade.
              <br />
              Soil conditions: Some ornamental plants prefer well-drained soil,
              while others prefer moist soil.
              <br />
              Maintenance requirements: Some ornamental plants require very
              little maintenance, while others require more regular pruning and
              care.
              <br />
              Personal preferences: Of course, you should also choose ornamental
              plants that you personally find attractive.
              <br /> <br />
              We Can provide wide range of Ornaments plants at your door step at
              a very reasonable prices. Simply, Contact us.{" "}
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default Ornamental;
