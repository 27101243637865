import React from "react";

function Palms() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/palm/palm_1.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Palms</h1>
            <p>
              Palms are a diverse group of flowering plants in the family
              Arecaceae. There are over 2,600 species of palms, found in
              tropical and subtropical regions around the world. Palms are known
              for their tall, slender trunks and their large, fan-shaped or
              feather-shaped leaves.
              <br />
              <br />
              Palms can be grown in a variety of climates, but they prefer warm
              temperatures and well-drained soil. Palms are relatively
              low-maintenance plants, but they do require regular watering,
              fertilization, and pruning.
              <br /> <br />
              We can provide wide varieties of palms e.g. Coconut palm, Date
              palm, Areca Palm, Majesty Palm Sago Palm etc at your door step at
              a very reasonable prices. Just drop an enquiry and we will reply
              as early as possible. Or simply, Contact us.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default Palms;
