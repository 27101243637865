import React from "react";

function Fruits() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="../static/content/fruits/fruits_2.jpeg" alt="" />
            <img src="../static/content/fruits/fruits_3.jpeg" alt="" />
            <img src="../static/content/fruits/fruits_4.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Fruit Plants</h1>
            <p>
              Fruit plants encompass plants that yield fruits, which are the
              matured ovaries of flowering plants. These fruits are typically
              fleshy, containing seeds, and serve as valuable sources of food
              for both humans and animals. Fruit plants exhibit adaptability to
              various climates and soil types, existing as either annual or
              perennial species.
              <br />
              <br />
              Benefits of cultivating fruit plants:
              <br />
              <br />
              Nutrition: Fruits serve as rich sources of essential vitamins,
              minerals, and fiber vital for human health.
              <br />
              Economic Value: Fruit plants represent a valuable source of income
              for farmers and cultivators.
              <br />
              Environmental Advantages: Fruit plants aid in soil erosion
              prevention and contribute to enhancing air quality.
              <br />
              Aesthetic Appeal: Fruit plants enhance the visual appeal of
              gardens and landscapes.
              <br />
              <br />
              Maintaining fruit plants involves several key aspects:
              <br />
              <br />
              Watering: Regular watering, especially during dry spells, is
              crucial for fruit plants.
              <br />
              Fertilization: Fruit plants require consistent fertilization with
              a balanced fertilizer.
              <br />
              Pruning: Regular pruning is necessary to eliminate dead or
              diseased branches and promote new growth.
              <br />
              Pest and Disease Control: Fruit plants are prone to pests and
              diseases, requiring proactive measures for control.
              <br />
              <br />
              Through our extensive selection of Fruit plants, securely
              deliverable to every corner of India, these plants promise not
              only years of enjoyment but also a bountiful harvest of delectable
              fruits with the right attention and care.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default Fruits;
