import React from "react";

function NanaBamboo() {
  return (
    <main>
      <div className="details_flower">
        <div className="details_flower_images">
          <img src="static/content/bamboo/nana_B/nana_b_1.jpeg" alt="" />
          <img src="static/content/bamboo/nana_B/nana_b_2.jpeg" alt="" />
        </div>
        <div className="details_flower_description">
          <h1>Nana Bamboo</h1>
          <p>
            Nana bamboo, also known as Bambusa multiplex, is a beautiful and
            versatile bamboo species that is native to Southeast Asia. It is
            known for its slender culms, delicate foliage, and compact growth
            habit. Nana bamboo is a popular ornamental plant, and is also used
            in bonsai and hedges.
            <br />
            <br />
            Nana bamboo can grow up to 10 feet tall, but is typically smaller
            when grown in containers. It prefers full sun to partial shade, and
            well-drained soil. Nana bamboo is relatively drought tolerant, but
            can benefit from regular watering.
            <br />
            <br />
            To care for Nana bamboo, water it regularly, especially during the
            hot summer months. Fertilize the plant once a month during the
            growing season with a balanced fertilizer. Nana bamboo is relatively
            pest and disease resistant.
            <br />
            <br />
            Nana bamboo is a beautiful and versatile plant that can add a touch
            of elegance and sophistication to any garden or home. It is also a
            good choice for small spaces, as it does not require a lot of room
            to grow.
            <br />
            <br />
            Here are some of the benefits of Nana bamboo:
            <br />
            1- It is a beautiful and ornamental plant.
            <br />
            2- It is relatively easy to care for.
            <br />
            3- It is drought tolerant.
            <br />
            4- It is pest and disease resistant.
            <br />
            5- It has a compact growth habit, making it ideal for small spaces.
          </p>
        </div>
      </div>
    </main>
  );
}

export default NanaBamboo;
