import React from "react";

function Avenues() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="../static/content/avenues/avenues_1.jpeg" alt="" />
            <img src="static/content/avenues/avenues_2.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Avenue Plants</h1>
            <p>
              Avenue Plants, which are available in our Nursery, refer to large
              trees typically planted along streets or roads. These plants serve
              the purpose of offering shade and enhancing the aesthetics of the
              surroundings.
              <br />
              <br />
              Avenue plants come with numerous advantages for communities:
              <br />
              They provide shade, aiding in cooling the air and mitigating heat
              stress.
              <br />
              Avenue plants contribute to reducing noise pollution.
              <br />
              They assist in enhancing air quality by filtering out dust and
              pollutants.
              <br />
              They have the potential to increase property values.
              <br />
              These plants also contribute to fostering a sense of place and
              community spirit.
              <br />
              <br />
              Avenue plants constitute a crucial element of urban landscapes,
              playing a significant role in elevating the quality of life for
              residents.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default Avenues;
