import React from "react";

function Shrubs() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/shrubs/shrubs_1.jpg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Shrubs</h1>
            <p>
              Shrubs are woody plants that are smaller than trees but larger
              than herbs. They typically have multiple stems and grow to a
              height of 6-10 meters (20-33 feet). Shrubs are found in a variety
              of habitats, including forests, grasslands, and deserts.
              <br />
              <br />
              Shrubs play an important role in the environment. They provide
              food and shelter for wildlife, and they help to prevent erosion.
              Shrubs are also a popular ornamental plant, and they are often
              used in landscaping.
              <br />
              <br />
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default Shrubs;
