import React from "react";
import { useState } from "react";

function Enquiry() {
  const [showAlert, setShowAlert] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    number: "",
    title: "",
    message: "",
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling animation
    });
  };

  const onChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    document.getElementById("formButton").disabled = true;
    e.preventDefault();
    let dataToSend = userData;
    let body = {
      email: dataToSend,
    };

    fetch(
      "https://api.sheety.co/220cf21062657e0ece899d8c5505f080/nursery/emails",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.json())
      .then((json) => {
        scrollToTop();
        setShowAlert(true);
        document.getElementById("formButton").disabled = false;
      })
      .catch(() => {
        alert("Some Problem occured. Please Try again.");
        document.getElementById("formButton").disabled = false;
      });

    setUserData({
      name: "",
      email: "",
      number: "",
      title: "",
      message: "",
    });
    setTimeout(() => {
      setShowAlert(false);
    }, 6000);
  };

  return (
    <>
      <div
        className="alert alert-success"
        role="alert"
        style={showAlert ? { display: "block" } : { display: "none" }}
      >
        Thank you for showing interest and raising a query. We will respond to
        you as soon as possible.
      </div>
      <main>
        <form onSubmit={onSubmit}>
          <div className="details_flower">
            <div className="details_flower_description">
              <h1>Enquiry</h1>
            </div>
            <div className="form">
              <div className="mb-3">
                <label htmlFor="formName" className="form-label">
                  Your Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="formName"
                  name="name"
                  value={userData.name}
                  onChange={onChange}
                  autoFocus
                />
              </div>
              <div className="mb-3">
                <label htmlFor="formEmail" className="form-label">
                  Email address <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="formEmail"
                  name="email"
                  value={userData.email}
                  onChange={onChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="formNumber" className="form-label">
                  Contact No.
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="formNumber"
                  name="number"
                  value={userData.number}
                  onChange={onChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="formTitle" className="form-label">
                  Enquiry Title <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="formTitle"
                  name="title"
                  value={userData.title}
                  onChange={onChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="formMessage" className="form-label">
                  Enquiry Details <span style={{ color: "red" }}>*</span>
                </label>
                <textarea
                  className="form-control"
                  id="formMessage"
                  rows="3"
                  name="message"
                  value={userData.message}
                  onChange={onChange}
                  required
                ></textarea>
              </div>

              <div className="mb3 my-3">
                <button className="btn btn-outline-success" id="formButton">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </main>
    </>
  );
}

export default Enquiry;
