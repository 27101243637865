import React from "react";

function CCAM() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/CCAM/CCAM_1.jpeg" alt="" />
            <img src="static/content/CCAM/CCAM_2.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Celosia Cristata Armor Mix</h1>
            <p>
              Celosia cristata Armor Mix is a stunning blend of vibrant colors
              that will add a touch of drama to any garden. The large,
              brain-shaped flowers are held above lush foliage on sturdy stems,
              making them a truly eye-catching addition to any landscape.
              <br />
              <br />
              The Armor Mix includes a wide range of colors, including red,
              orange, yellow, pink, and purple. The flowers can be solid colors,
              bi-colored, or even tri-colored. This mix is sure to add a touch
              of excitement to any garden.
              <br />
              <br />
              Celosia cristata Armor Mix is a relatively easy plant to grow. It
              prefers full sun and well-drained soil. The plants are drought
              tolerant and can survive on very little water. Celosia cristata
              Armor Mix can be propagated from seed or cuttings.
              <br />
              <br />
              The flowers of Celosia cristata Armor Mix are long-lasting and can
              be enjoyed for many weeks. They are also a popular choice for cut
              flowers.
              <br />
              <br />
              If you are looking for a colorful and eye-catching addition to
              your garden, Celosia cristata Armor Mix is the perfect choice.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default CCAM;
