import "./FirstBanner.css"
import React from 'react'

export default function FirstBanner() {
  return (
    <>
      
      <div className="first_banner">
                        <img src="static/flowers/new.png" alt="banner" />
                    </div>

    </>
  )
}
