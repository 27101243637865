import React from "react";
import QuickAccess from "../components/QuickAccess/QuickAccess";
import FirstBanner from "../components/FirstBanner/FirstBanner";
import Blog from "../components/Blog/Blog";
import Reviews from "../components/Reviews/Reviews";

export default function Home() {
  return (
    <>
      <QuickAccess />
      <FirstBanner />
      <Blog />
      <Reviews />
    </>
  );
}
