import React from "react";
import {Link} from 'react-router-dom'

function Seasonal_flowers() {
  return (
    <main>
      <div className="content">
        <div className="card">
          <img
            src="static/content/vinca/Vinca_2.jpeg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Vinca</h5>
            <p className="card-text">
              The vinca flower, also known as periwinkle....
            </p>
            <Link to="/vinca" className="btn btn-primary">
              Details
            </Link>
          </div>
        </div>

        <div className="card">
          <img
            src="static/content/CCAM/CCAM_2.jpeg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Celosia Cristata Armor Mix</h5>
            <p className="card-text">
              Celosia cristata Armor Mix is a stunning....{" "}
            </p>
            <Link to="/CCAM" className="btn btn-primary">
              Details
            </Link>
          </div>
        </div>

        <div className="card">
          <img
            src="static/content/CPIC/CPIC_1.jpeg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Celosia Plumosa Ice Cream</h5>
            <p className="card-text">
              Celosia plumosa Ice Cream is a stunning....
            </p>
            <Link to="/CPIC" className="btn btn-primary">
              Details
            </Link>
          </div>
        </div>

        <div className="card">
          <img
            src="static/content/coleus/coleus_1.jpeg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Coleus Wizard</h5>
            <p className="card-text">
              Coleus Wizard is a coleus variety with....
            </p>
            <Link to="/coleus" className="btn btn-primary">
              Details
            </Link>
          </div>
        </div>

        <div className="card">
          <img
            src="static/content/hyposystis/hyposystis_1.jpeg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Hyposystis Splash Mix</h5>
            <p className="card-text">
              Hyposystis Splash Mix is a vibrant flowering....{" "}
            </p>
            <Link to="/hyposystis" className="btn btn-primary">
              Details
            </Link>
          </div>
        </div>

        <div className="card">
          <img
            src="static/content/torenia/torenia_1.jpeg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Torenia Kauai Mix</h5>
            <p className="card-text">
              Torenia Kauai Mix is a colorful flowering....
            </p>
            <Link to="/torenia" className="btn btn-primary">
              Details
            </Link>
          </div>
        </div>

        <div className="card">
          <img
            src="static/content/dianthus/dianthus_1.jpeg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Dianthus Ideal Select</h5>
            <p className="card-text">
              Dianthus Ideal Select is a hybrid of Dianthus....
            </p>
            <Link to="/dianthus" className="btn btn-primary">
              Details
            </Link>
          </div>
        </div>

        <div className="card">
          <img
            src="static/content/salvia_vista/salvia_1.jpeg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Salvia Vista Red</h5>
            <p className="card-text">
              Salvia Vista Red is a dwarf salvia cultivar....
            </p>
            <Link to="/salvia" className="btn btn-primary">
              Details
            </Link>
          </div>
        </div>

        <div className="card">
          <img
            src="static/content/petunia/petunia_1.jpeg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Petunia Picote Mix</h5>
            <p className="card-text">
              Petunia Picote Mix is a blend of petunias....
            </p>
            <Link to="/petunia" className="btn btn-primary">
              Details
            </Link>
          </div>
        </div>

        <div className="card">
          <img
            src="static/content/gazania/gazania_1.jpeg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Gazania Newday Mix</h5>
            <p className="card-text">
              Gazania Newday Mix is a stunning blend of....{" "}
            </p>
            <Link to="/gazania" className="btn btn-primary">
              Details
            </Link>
          </div>
        </div>

        <div className="card">
          <img
            src="static/content/aster/aster_1.jpeg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Aster Tall Mix</h5>
            <p className="card-text">
              Aster Tall Mix is a captivating blend of....{" "}
            </p>
            <Link to="/aster" className="btn btn-primary">
              Details
            </Link>
          </div>
        </div>

        <div className="card">
          <img
            src="static/content/calendula/calendula_1.jpeg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Calendula Bon Bon Mix</h5>
            <p className="card-text">
              Calendula Bon Bon Mix is a delightful....
            </p>
            <Link to="/calendula" className="btn btn-primary">
              Details
            </Link>
          </div>
        </div>

        <div className="card">
          <img
            src="static/content/begonia/begonia_2_new.jpg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Begonia Cocktail Mix</h5>
            <p className="card-text">
              Begonia Cocktail Mix is a blend of colorful....
            </p>
            <Link to="/begonia" className="btn btn-primary">
              Details
            </Link>
          </div>
        </div>

        <div className="card">
          <img
            src="static/content/dianthus_diana/dianthus_diana_1.jpeg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Dianthus Diana Mix</h5>
            <p className="card-text">
              Dianthus Diana Mix is a perfect choice....
            </p>
            <Link to="/dianthus_diana" className="btn btn-primary">
              Details
            </Link>
          </div>
        </div>

        <div className="card">
          <img
            src="static/content/marigold/Marigold_1_crop.jpg"
            className="card-img-top"
            alt="..."
          />
          <div className="card-body">
            <h5 className="card-title">Marigold</h5>
            <p className="card-text">The marigold, also known as.... </p>
            <Link to="/marigold" className="btn btn-primary">
              Details
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Seasonal_flowers;
