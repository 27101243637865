import React from "react";

function BlackBamboo() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/bamboo/black_B/black_B_1.jpeg" alt="" />
            <img src="static/content/bamboo/black_B/black_B_2.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Black Bamboo</h1>
            <p>
              Black bamboo, also known as Phyllostachys nigra, is a stunning and
              unique bamboo species native to southern China. It is known for
              its jet-black culms, which contrast beautifully with its lush
              green foliage. Black bamboo is a popular ornamental plant, and is
              also used in construction and furniture making.
              <br />
              <br />
              Black bamboo can grow up to 35 feet tall, but is typically smaller
              when grown in containers. It prefers full sun to partial shade,
              and well-drained soil. Black bamboo is relatively drought
              tolerant, but can benefit from regular watering.
              <br />
              <br />
              To care for black bamboo, water it regularly, especially during
              the hot summer months. Fertilize the plant once a month during the
              growing season with a balanced fertilizer. Black bamboo is
              relatively pest and disease resistant.
              <br />
              <br />
              Black bamboo is a beautiful and versatile plant that can add a
              touch of elegance and sophistication to any garden or home. It is
              also a good choice for small spaces, as it does not require a lot
              of room to grow.
              <br />
              <br />
              Here are some of the benefits of black bamboo:
              <br />
              1- It has a compact growth habit, making it ideal for small
              spaces.
              <br />
              2- Easy to care for
              <br />
              3- Drought tolerant.
              <br />
              4- Pest and disease resistant.
              <br />
              5- Beautiful and ornamental with unique black culms.
              <br />
              <br />
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default BlackBamboo;
