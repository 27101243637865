import React from "react";

function GiantBamboo() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/bamboo/giant_B/giant_B_1.jpeg" alt="" />
            <img src="static/content/bamboo/giant_B/giant_B_2.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Giant Bamboo</h1>
            <p>
              Giant bamboo, also known as Dendrocalamus giganteus, is a giant
              tropical and subtropical, dense-clumping species native to
              Southeast Asia. It is one of the largest bamboo species in the
              world, with culms that can reach up to 100 feet tall and 12 inches
              in diameter. Giant bamboo is a fast-growing plant, and new shoots
              can grow up to 12 inches per day.
              <br />
              <br />
              Here are some of the specific uses of giant bamboo:
              <br />
              <br />
              Construction: Giant bamboo is a strong and durable material that
              can be used to build a variety of structures, including houses,
              bridges, and scaffolding. It is also used to make roofing and
              flooring materials.
              <br />
              <br />
              Furniture making: Giant bamboo is often used to make furniture,
              such as chairs, tables, beds, and cabinets. It is also used to
              make baskets, mats, and other household items.
              <br />
              <br />
              Papermaking: Giant bamboo is a good source of fiber for
              papermaking. It is used to make a variety of paper products,
              including writing paper, printing paper, and packaging materials.
              <br />
              <br />
              Food: Giant bamboo shoots are edible and are often eaten as a
              vegetable. They are a good source of fiber and vitamins.
              <br />
              <br />
              Other products: Giant bamboo is also used to make a variety of
              other products, such as musical instruments, sports equipment, and
              toys.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default GiantBamboo;
