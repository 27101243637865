import React from "react";

function Hyposystis() {
  return (
    <main>
      <div className="details_flower">
        <div className="details_flower_images">
          <img src="static/content/hyposystis/hyposystis_1.jpeg" alt="" />
        </div>
        <div className="details_flower_description">
          <h1>Hyposystis Splash Mix</h1>
          <p>
            Hyposystis Splash Mix is a vibrant flowering plant with star-shaped
            flowers in a wide range of colors, often spotted or splashed with
            contrasting hues. The evergreen plants grow to a height of 30-60 cm,
            with slender stems covered in small, oval leaves. Easy to grow, they
            prefer full sun to partial shade and well-drained soil. Popular for
            gardens, borders, containers, and cut flowers, their long-lasting
            blooms are enjoyed for many weeks.
          </p>
        </div>
      </div>
    </main>
  );
}

export default Hyposystis;
