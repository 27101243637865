import React from "react";

export default function About() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_description">
            <h1>About us</h1>
            <p>
              Welcome to AAA Nursery and Farms, your premier destination for
              wholesale and retail botanical treasures. At AAA, we're dedicated
              to providing you with high-quality plants at prices that are not
              only reasonable but also highly competitive in the market. Our
              mission is to help you transform your space with the beauty and
              allure of nature, and we offer a diverse selection of plants to
              make that vision a reality.
              <br />
              <br />
              With a commitment to your satisfaction, we proudly offer an
              extensive range of plant categories, catering to your every need
              and desire. Discover the following eight categories of botanical
              wonders:
              <br />
              <br />
              <b>1. Bamboo</b>: Embrace the serenity of bamboo, known for its
              elegance and versatility. Whether for privacy, aesthetics, or
              landscaping, our bamboo selection is sure to enchant.
              <br />
              <b>2. Bonsai</b>: Unleash your inner artist with our exquisite
              bonsai collection. These miniature marvels allow you to create
              your own little world of tranquility and charm.
              <br />
              <b>3. Seasonal Flowers</b>: Our seasonal flowers bring life and
              color to your surroundings throughout the year. From the vibrant
              hues of summer to the serene charm of winter, these blossoms are a
              visual delight.
              <br />
              <b>4. Creepers</b>: Let nature's tendrils embrace your spaces with
              our captivating creeper plants. Perfect for adding a touch of
              whimsy and elegance to any area.
              <br />
              <b>5. Ground Covers</b>: Enhance your landscape with our ground
              cover plants. They not only add texture and beauty but also serve
              as nature's carpet, transforming your space into a lush paradise.
              <br />
              <b>6. Ornamentals</b>: Elevate the aesthetics of your surroundings
              with our ornamental plants. These eye-catching treasures are sure
              to make a statement in any garden or landscape.
              <br />
              <b>7. Palms</b>: Add a touch of the tropics to your environment
              with our stunning palm collection. Their graceful fronds and
              exotic charm create a paradise in your own backyard.
              <br />
              <b>8. Shrubs</b>: For those seeking variety and structure, our
              shrub selection offers an array of options to craft well-defined,
              visually pleasing landscapes.
              <br />
              <b>9. Avenue Plants</b>: Experience the grandeur of trees lining
              streets and roads, enriching your surroundings with shade, beauty,
              and a sense of community.
              <br />
              <b>10. Fruit Plants</b>: Delight in the bounty of fruit-bearing
              plants that not only enhance landscapes but also provide
              nutritious, flavorful fruits for your enjoyment.
              <br />
              <b>11. Forestry Plants</b>: Embrace the significance of trees and
              shrubs in forests, contributing to timber production, wildlife
              habitat, and ecological balance, all while playing a vital role in
              preserving healthy forest ecosystems.
              <br />
              <br />
              Cultivating expertise and dedication <b>over 15 years</b> in the
              industry, our business thrives amidst the tranquil expanse of the
              lush green belt. Our location not only adds to the beauty of our
              surroundings but also serves as the backdrop to our continued
              commitment to quality service and operations.
              <br />
              <br />
              Whether you're a landscaping professional looking to source in
              bulk or a homeowner with a green thumb, we cater to both wholesale
              and retail customers. Our competitive prices make it easy for
              everyone to access the finest plants and add a touch of nature to
              their surroundings.
              <br />
              <br />
              At AAA Nursery and Farms, your satisfaction is our top priority.
              We're here to assist you in selecting the perfect plants for your
              space, whether it's a cozy home garden or a spacious landscape
              project. Our team is always ready to answer your questions and
              help you find the ideal botanical companions to adorn your
              surroundings.
              <br />
              <br />
              Thank you for considering us as your partner in making your space
              truly remarkable. We look forward to the opportunity to serve you
              and provide you with the best botanical treasures, ensuring your
              environment bursts with life, color, and natural beauty.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}
