import React from "react";

function Marigold() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/marigold/Marigold_1_crop.jpg" alt="" />
            <img src="static/content/marigold/Marigold_2_crop.jpg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Marigold</h1>
            <p>
              The marigold, also known as genda phool in India, is a vibrant and
              ubiquitous flower that holds a special place in Indian culture and
              traditions. Native to Mexico and Central America, marigolds were
              introduced to India by European traders in the 16th century. They
              quickly became popular due to their adaptability to the Indian
              climate and their striking beauty.
              <br />
              <br />
              Marigolds are considered sacred flowers in Hinduism and are often
              offered to deities during religious ceremonies. They are also
              associated with auspicious occasions such as weddings, festivals,
              and childbirth. The bright yellow and orange hues of marigolds are
              said to represent purity, prosperity, and joy.
              <br />
              <br />
              In addition to their religious significance, marigolds are also
              valued for their medicinal properties. The flowers are said to
              have anti-inflammatory, antiseptic, and digestive benefits. They
              are also used in traditional Indian medicine to treat a variety of
              ailments, such as skin problems, wounds, and fevers.
              <br />
              <br />
              Marigolds are a common sight in Indian gardens and homes. They are
              also grown commercially for their flowers, which are used in
              garlands, bouquets, and other decorative arrangements. The dried
              petals of marigolds are also used as a natural dye.
              <br />
              <br />
              The marigold is a truly versatile and beloved flower in India. It
              is a symbol of hope, happiness, and new beginnings.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default Marigold;
