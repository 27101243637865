import React from "react";

function PP() {
  return (
    <>
      <main>
        <div className="privacy_policy">
          <h3>AAA Nursery & Farms: Privacy Policy</h3>
          <p className="last_updated"> Last Updated: October 27, 2023</p>
          <p>Welcome to AAA Nursery & Farms!</p>
          <p>
            At AAA Nursery & Farms, we value your privacy and are committed to
            protecting your personal information. This Privacy Policy explains
            how we collect, use, and safeguard your data when you visit our
            website.
          </p>
          <p>
            <b>1. Information We Collect</b>
          </p>
          <p>
            Personal Information: We may collect personal information such as
            your name, email address, and phone number only when you submit
            inquiries or sign up for newsletters.
            <br /> Usage Information: We do not collect non-personal information
            about your interactions with our website, including your IP address,
            browser type, and pages visited. This information helps us improve
            our website and services.
          </p>
          <p>
            <b>2. How We Use Your Information</b>
          </p>
          <p>
            Communication: We may use your personal information to respond to
            your inquiries, send you updates about our nursery, and deliver
            newsletters or promotional materials.
            <br /> Website Improvement: Usage information is used to analyze
            website traffic, improve our website's content, and enhance user
            experience.
          </p>

          <p>
            <b>3. Third-Party Disclosure</b>
          </p>
          <p>
            We do not sell, trade, or transfer your personal information to
            third parties. However, we may share your data with trusted service
            providers who assist us in operating our website, conducting our
            business, or serving you.
          </p>

          <p>
            <b>4. Cookies</b>
          </p>
          <p>
            We may use cookies on our website to enhance your experience. You
            can choose to accept or decline cookies through your web browser
            settings. Please note that disabling cookies may affect your
            experience on our website.
          </p>

          <p>
            <b>5. Data Security</b>
          </p>
          <p>
            We employ appropriate data collection, storage, and processing
            practices to protect your information against unauthorized access,
            alteration, disclosure, or destruction.
          </p>

          <p>
            <b>6. Your Rights</b>
          </p>
          <p>
            You have the right to access, correct, or delete your personal
            information. You can also opt-out of receiving promotional materials
            at any time by using the provided unsubscribe links.
          </p>

          <p>
            <b>7. Children's Privacy</b>
          </p>
          <p>
            Our website is not intended for children/adolescents under 18 years
            of age. We do not knowingly collect information from
            children/adolescents under 18. If you believe a child has provided
            us with their personal information, please contact us to have it
            removed.
          </p>

          <p>
            <b>8. Changes to this Privacy Policy</b>
          </p>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be effective immediately upon posting. It is your
            responsibility to review this policy periodically for updates.
          </p>
          <p>
            <b>9. Contact Information</b>
          </p>
          <p>
            If you have any questions or concerns regarding our Privacy Policy,
            please contact us.
          </p>

          <p>
            {" "}
            By using our website, you consent to the terms outlined in this
            Privacy Policy. Thank you for trusting AAA Nursery & Farms with your
            personal information.{" "}
          </p>
        </div>
      </main>
    </>
  );
}

export default PP;
