import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <>
      <footer>
        <div className="footer">
          <div className="footer-element">
            <div>
              <img src="static/phone.png" alt="phone" />
              <h4>|</h4>
              <img src="static/whatsapp.png" alt="whatsapp" />
              <h4>Call</h4>
            </div>

            <p>
              +919027845337 <br />
              +917505720630
            </p>
          </div>
          <div className="footer-element">
            <div>
              <img src="static/gmail.png" alt="gmail" />
              <h4>Mail</h4>
            </div>
            <p>aaanurseryandfarms@gmail.com</p>
          </div>
          <div className="footer-element">
            <div>
              <img src="static/map.png" alt="map" />
              <h4>Address</h4>
            </div>
            <p>
              AAA Nursery & Farms (Daroga ji ka farm) <br />
              Behind Manota Police station <br />
              Hasanpur - Gajraula road <br />
              Tehsile Hasanpur, Amroha UP
            </p>
          </div>
          <div className="footer-element">
            <div>
              <h4>Follow Us</h4>
            </div>
            <p style={{ paddingTop: "10px" }}>
              <Link to="https://m.facebook.com/profile.php/?id=61552823493475">
                <img src="static/flowers/fb_1.png" alt="" />
              </Link>
              <Link to="https://www.instagram.com/aaanurseryandfarms/">
                <img src="static/flowers/insta_1.png" alt="" />
              </Link>
              <Link to="https://youtube.com/@AAANURSERYAndFARMS?feature=shared">
                <img
                  src="static/flowers/youtube_1.png"
                  alt=""
                  style={{ paddingLeft: "4px" }}
                />
              </Link>
            </p>
          </div>
        </div>
        <p>
          <Link style={{ color: "grey", margin: "0px 4px" }} to="/">
            Home
          </Link>
          |
          <Link style={{ color: "grey", margin: "0px 4px" }} to="/Terms_of_use">
            Terms of Use
          </Link>
          |
          <Link
            style={{ color: "grey", margin: "0px 4px" }}
            to="/privacy_policy"
          >
            Privacy Policy
          </Link>
          |
          <Link style={{ color: "grey", margin: "0px 4px" }} to="/contactus">
            Contact Us
          </Link>
          <br />
          copyright © 2023 AAA Nursery & Farms. All rights reserved.
        </p>
      </footer>
    </>
  );
}

export default Footer;
