import React from "react";
import {Link} from 'react-router-dom'


function ContactUs() {
  return (
    <>
      <main>
        {" "}
        <div className="contacts-group">
          <div className="group-1">
            <div
              className="card"
              style={{
                width: "18rem",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <img
                src="static/phone.png"
                className="card-img-top"
                style={{ width: "50px", height: "50px" }}
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title">Call</h5>
                <p className="card-text">
                  +919027845337 <br /> +917505720630{" "}
                </p>
              </div>
            </div>
            <Link to="mailto:aaanurseryandfarms@gmail.com">
              {" "}
              <div
                className="card"
                style={{
                  width: "18rem",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <img
                  src="static/gmail.png"
                  className="card-img-top"
                  style={{ width: "50px", height: "50px" }}
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Mail</h5>
                  <p className="card-text">aaanurseryandfarms@gmail.com</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="group-2">
            <Link to="https://maps.app.goo.gl/wioghuTNQNstw5BS8">
              <div
                className="card"
                style={{
                  width: "18rem",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <img
                  src="static/map.png"
                  className="card-img-top"
                  style={{ width: "50px", height: "50px" }}
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Address</h5>
                  <p className="card-text contactus-address">
                    AAA Nursery & Farms (Daroga ji ka farm) <br />
                    Behind Manota Police station <br />
                    Hasanpur - Gajraula road <br />
                    Tehsile Hasanpur, Amroha UP
                  </p>
                </div>
              </div>
            </Link>
            <Link to="https://api.whatsapp.com/send?phone=+919027845337&text=Hello%20there,%20I%20am%20interested%20in%20your%20nursery!">
              {" "}
              <div
                className="card"
                style={{
                  width: "18rem",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <img
                  src="static/whatsapp.png"
                  className="card-img-top"
                  style={{ width: "50px", height: "50px" }}
                  alt="..."
                />
                <div className="card-body">
                  <h5 className="card-title">Whatsapp</h5>
                  <p className="card-text">
                    +919027845337 <br /> +917505720630{" "}
                  </p>
                </div>
              </div>
            </Link>

            <div
              className="card"
              style={{
                width: "18rem",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <img
                src="static/follow_us.png"
                className="card-img-top"
                style={{ width: "50px", height: "50px" }}
                alt="..."
              />
              <div className="card-body">
                <h5 className="card-title">Follow Us</h5>
                <p className="card-text">
                  <Link to="https://m.facebook.com/profile.php/?id=61552823493475">
                    <img src="static/flowers/fb_1.png" alt="" />
                  </Link>
                  <Link to="https://www.instagram.com/aaanurseryandfarms/">
                    <img src="static/flowers/insta_1.png" alt="" />
                  </Link>
                  <Link to="https://youtube.com/@AAANURSERYAndFARMS?feature=shared">
                    {" "}
                    <img
                      src="static/flowers/youtube_1.png"
                      alt=""
                      style={{ paddingLeft: "4px" }}
                    />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ContactUs;
