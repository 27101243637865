import React from "react";

export default function Torenia() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="static/content/torenia/torenia_1.jpeg" alt="" />
            <img src="static/content/torenia/torenia_2.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Torenia Kauai Mix</h1>
            <p>
              Torenia Kauai Mix is a colorful flowering plant with
              trumpet-shaped flowers in a blend of red, orange, yellow, pink,
              and purple. It is a compact plant that is ideal for hanging
              baskets, window boxes, and containers. It prefers full sun to
              partial shade and moist, well-drained soil. It is easy to grow and
              attracts hummingbirds and butterflies.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}
