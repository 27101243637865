import React from "react";

function Forestry() {
  return (
    <>
      <main>
        <div className="details_flower">
          <div className="details_flower_images">
            <img src="../static/content/forestry/forestry_1.jpeg" alt="" />
            <img src="../static/content/forestry/forestry_3.jpeg" alt="" />
            <img src="../static/content/forestry/forestry_2.jpeg" alt="" />
          </div>
          <div className="details_flower_description">
            <h1>Forestry Plants </h1>
            <p>
              Forestry plants encompass trees and shrubs strategically planted
              in forests, serving various purposes like timber production,
              wildlife habitat maintenance, and conservation efforts. They play
              a pivotal role in sustaining robust forest ecosystems, offering
              numerous advantages to both humans and the environment.
              <br />
              <br />
              Forestry plants encompass various types, among which the following
              are prevalent:
              <br />
              <br />
              Conifers: These trees, such as pines, firs, and spruces, bear
              cones and needles. They're prized for their quick growth and
              high-quality wood, often used in timber production.
              <br />
              Hardwoods: These trees, including oaks, maples, and birches,
              feature broad leaves and find applications in furniture making,
              flooring, and other wood-related products.
              <br />
              Shrubs: Smaller than trees, shrubs serve purposes like wildlife
              habitat creation, erosion control, and landscaping.
              <br />
              Other woody plants: This category includes vines, lianas, and
              bamboo, which also contribute to forestry goals.
              <br />
              <br />
              By offering a diverse range of Forestry plants and ensuring secure
              delivery to any part of India, we play a part in supporting these
              plants' collective contribution to the diverse ecosystem and their
              multifaceted roles in forest management and preservation.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}

export default Forestry;
