import React from "react";

function Creepers() {
  return (
    <main>
      <div className="details_flower">
        <div className="details_flower_images">
          <img src="static/content/creeper/creeper_1.jpg" alt="" />
        </div>

        <div className="details_flower_description">
          <h1>Creepers</h1>
          <p>
            Creepers are plants that spread along the ground or on other
            surfaces by means of stems or branches. They often have long, thin
            stems that are not strong enough to support themselves upright, so
            they rely on other plants or objects for support. Creepers can be
            found in a variety of habitats, including forests, grasslands, and
            deserts.
            <br />
            <br />
            Here are some of the benefits of creepers:
            <br />
            Erosion control: Creepers can help to prevent erosion by covering
            the ground and holding soil in place.
            <br />
            Wildlife habitat: Creepers can provide a habitat for a variety of
            wildlife, including birds, insects, and reptiles.
            <br />
            Ornamental value: Many creepers, such as wisteria and vinca, are
            attractive plants that can be used to decorate gardens and
            landscapes.
            <br />
            <br />
            Here are some of the drawbacks of creepers:
            <br />
            Invasiveness: Some creepers, such as English ivy, can be invasive
            and damage native plants and trees.
            <br />
            Creepers can compete with other plants for water and nutrients.
            <br />
            Damage to structures: Some creepers, such as ivy, can damage
            buildings and other structures by growing into their cracks and
            crevices.
            <br />
            <br />
            We can provide vast variety Creepers at a very reasonable prices.
          </p>
        </div>
      </div>
    </main>
  );
}

export default Creepers;
