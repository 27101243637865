import React from "react";
import {Link} from 'react-router-dom'

function Bamboo() {
  return (
    <>
      <main>
        <div className="content">
          <div className="card">
            <img
              src="static/content/bamboo/Golden_B/golden_b_2.jpeg"
              className="card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title">Golden Bamboo</h5>
              <p className="card-text">
                Bambusa vulgaris, commonly known as....{" "}
              </p>
              <Link to="/golden_B" className="btn btn-primary">
                Details
              </Link>
            </div>
          </div>

          <div className="card">
            <img
              src="static/content/bamboo/belly_B/belly_B_1.jpeg"
              className="card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title">Buddha Belly Bamboo</h5>
              <p className="card-text">Buddha belly bamboo, also known.... </p>
              <Link to="/belly_B" className="btn btn-primary">
                Details
              </Link>
            </div>
          </div>

          <div className="card">
            <img
              src="static/content/bamboo/nana_B/nana_b_2.jpeg"
              className="card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title">Nana Bamboo</h5>
              <p className="card-text">Nana bamboo, also known as .... </p>
              <Link to="/nana_B" className="btn btn-primary">
                Details
              </Link>
            </div>
          </div>

          <div className="card">
            <img
              src="static/content/bamboo/green_B/green_B_1.jpeg"
              className="card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title">Green Bamboo</h5>
              <p className="card-text">Bambusa bambos, also known as .... </p>
              <Link to="/green_B" className="btn btn-primary">
                Details
              </Link>
            </div>
          </div>

          <div className="card">
            <img
              src="static/content/bamboo/black_B/black_B_1.jpeg"
              className="card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title">Black Bamboo</h5>
              <p className="card-text">Black bamboo, also known as .... </p>
              <Link to="/black_B" className="btn btn-primary">
                Details
              </Link>
            </div>
          </div>

          <div className="card">
            <img
              src="static/content/bamboo/giant_B/giant_B_2.jpeg"
              className="card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title">Giant Bamboo</h5>
              <p className="card-text">Giant bamboo, also known as .... </p>
              <Link to="/giant_B" className="btn btn-primary">
                Details
              </Link>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Bamboo;
