import React from "react";
import { Link } from "react-router-dom";
import "./Blog.css";

function Blog() {
  return (
    <>
      <div className="blog_title">
        <p>From the Blog</p>
      </div>
      <div className="blog_articles">
        <div className="card" id="blog_items">
          <img
            src="static/content/palm/palm_2.jpg"
            className="card-img-top"
            alt="blog"
          />
          <div className="card-body">
            <h5 className="card-title">
              The Elegance and Diversity of Palms: Nature's Green Giants
            </h5>
            {/* <!-- <p className="card-text">Palms, with their majestic fronds and towering heights, are nature's green giants that grace landscapes around the world. These iconic plants have been an integral part of various cultures, landscapes, and ecosystems for centuries, offering more than just aesthetic appeal.</p> --> */}
            <Link to="/blog_palms" className="">
              Read More...
            </Link>
          </div>
        </div>
        <div className="card" id="blog_items">
          <img
            src="static/content/salvia_vista/salvia_2.jpeg"
            className="card-img-top"
            alt="blog"
          />
          <div className="card-body">
            <h5 className="card-title">
              Nature's Ever-Changing Beauty: Exploring the Enchantment of
              Seasonal Flowers
            </h5>
            {/* <!-- <p className="card-text">Palms, with their majestic fronds and towering heights, are nature's green giants that grace landscapes around the world. These iconic plants have been an integral part of various cultures, landscapes, and ecosystems for centuries, offering more than just aesthetic appeal.</p> --> */}
            <Link to="/blog_seasonal" className="">
              Read More...
            </Link>
          </div>
        </div>
        <div className="card" id="blog_items">
          <img
            src="static/content/ornamental/ornamental_1.jpg"
            className="card-img-top"
            alt="blog"
          />
          <div className="card-body">
            <h5 className="card-title">
              Ornamental Flowers: Nature's Artistry in the Garden
            </h5>
            {/* <!-- <p className="card-text">Palms, with their majestic fronds and towering heights, are nature's green giants that grace landscapes around the world. These iconic plants have been an integral part of various cultures, landscapes, and ecosystems for centuries, offering more than just aesthetic appeal.</p> --> */}
            <Link to="/blog_ornamental" className="">
              Read More...
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
